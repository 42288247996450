<template>
  <div class="page-class page-user-list">
    <div v-if="showUploadProgress">
      <h1 class="page-title text-center">{{ bulkUploadText }}</h1>
      <b-progress :value="uploadProgress" :max="100" show-progress animated></b-progress>
    </div>
    <h1 class="page-title text-center">{{ title }}</h1>
    <feedback-list-box
      :columns="columns"
      :rows="documents"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="ifAdmin()"
      :showUpload="ifAdmin()"
      :showEditText="true"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :filterOptions="filterOptions"
      :showFilter="true"
      :searchItems="searchItems"
      @delete="onDelete"
      addText="Upload New Document"
      uploadText="Bulk Upload"
      emptyText="No Document found. Please upload a document."
      editText="View/Edit"
      @add="onAdd"
      @edit="onEdit"
      @download="onDownload"
      @show-upload="onUpload"
      @edit-text="onEditText"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </feedback-list-box>
    <b-modal ref="editModal" title="Edit Document" size="mg">
      <h5 class="my-4">Document Remarks.</h5>

      <b-form-group>
        <div v-for="(keyPoint, index) in keyPoints" :key="keyPoint.id" class="mb-3">
          <div class="d-flex align-items-start">
            <b-form-textarea
              class="custom-textarea mr-3"
              placeholder="Enter key point details here"
              rows="1"
              max-rows="6"
              v-model="keyPoint.text"
              :class="{ 'dark-mode': isDarkMode }"
            ></b-form-textarea>
            <b-button
              v-if="ifAdmin() && (keyPoints.length > 1 || index > 0)"
              variant="danger"
              @click="deleteKeyPoint(index)"
              >Delete</b-button
            >
          </div>
        </div>
      </b-form-group>

      <b-button variant="primary" v-if="ifAdmin()" @click="addKeyPoint">Add More Remarks</b-button>

      <template #modal-footer>
        <b-button variant="primary" v-if="ifAdmin()" @click="displayKeyPointsData">Save</b-button>
        <b-button variant="secondary" @click="cancelEditDocument">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import csv from 'csvtojson';
import router from '@/router';
import _ from 'lodash';
import moment from 'moment';
import FeedbackListBox from '../../components/FeedbackListBox.vue';

export default {
  name: 'DocumentsList',
  components: { FeedbackListBox },
  metaInfo() {
    return {
      title: 'Documents List',
      meta: [
        {
          name: 'description',
          content: `Manage Documents List`
        }
      ]
    };
  },
  mounted() {
    // if (window.localStorage.getItem('hasCompletedChecklist') !== 'true') {
    //   this.$tours.myTour.start();
    // }
    if (_.isEmpty(this.$route.query)) {
      this.listDocuments({
        query: {
          ...this.$route.query
          // startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          // enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listDocuments({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listDocuments({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Documents List',

      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'Title',
          class: { 'text-center': true },
          textKey: 'title',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Document Type',
          class: { 'text-center': true },
          textKey: 'documentType',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Document Description',
          class: { 'text-center': true },
          textKey: 'description',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Active',
          class: { 'text-center': true },
          textKey: 'active',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Start Date',
          class: { 'text-center': true },
          textKey: 'startDate',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'End Date',
          class: { 'text-center': true },
          textKey: 'endDate',
          width: '8%'
        },
        // {
        //   type: 'string',
        //   headerText: 'Region',
        //   class: { 'text-center': true },
        //   textKey: 'regions',
        //   width: '8%'
        // },
        // {
        //   type: 'string',
        //   headerText: 'Cluster',
        //   class: { 'text-center': true },
        //   textKey: 'clusters',
        //   width: '6%'
        // },
        // {
        //   type: 'string',
        //   headerText: 'Formats',
        //   class: { 'text-center': true },
        //   textKey: 'formats',
        //   width: '6%'
        // },

        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeids',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Document Remarks',
          class: { 'text-center': true },
          htmlKey: 'remarks',
          width: '20%'
        },
        {
          type: 'string',
          headerText: 'Uploaded By',
          class: { 'text-center': true },
          textKey: 'uploadedByName',
          width: '9%'
        },

        {
          type: 'string',
          headerText: 'Uploaded At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '7%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '10%',
          functions: {
            edit: false,
            delete: true,
            download: true
          }
        }
      ],
      searchItems: ['Document Title', 'Store Id'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Active-True', text: 'Active-True' },
        { value: 'Active-False', text: 'Active-False' },
        { value: 'Type-SOP', text: 'Type-SOP' },
        { value: 'Type-PLANOGRAM', text: 'Type-PLANOGRAM' },
        { value: 'Type-LAYOUT', text: 'Type-LAYOUT' },
        { value: 'Type-C&B', text: 'Type-C&B' }
      ],
      showUploadProgress: false,
      uploadProgress: 0,
      bulkUploadText: '',
      keyPoints: [{ id: Date.now(), text: '' }],
      editingRowData: ''
    };
  },
  computed: {
    ...mapState('document', ['loading', 'baseUrl', 'documents', 'pagination']),
    ...mapState('auth', ['user']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  methods: {
    ...mapActions('document', [
      'listDocuments',
      'deleteOne',
      'uploadDocument',
      'postOne',
      'sendNotifications',
      'patchOne'
    ]),
    ifAdmin() {
      const isAdmin = this.user.roles.filter(role => role.name === 'ADMIN' || role.name === 'SUB_ADMIN');
      const isUpload = this.user.roles.filter(role => role.name === 'UPLOAD_DOCUMENTS_ROLE');

      if (isAdmin.length || isUpload.length) {
        return true;
      }
      return false;
    },
    onDownload({ row }) {
      window.open(`${row.documentUrl}`);
    },
    onEditText({ row }) {
      this.editingRowData = row;
      this.keyPoint = row.keyPoint;
      this.keyPoints = (row.completeRemarks.length > 0 ? row.completeRemarks : [{ id: Date.now(), text: '' }]).map(
        keyPoint => ({
          id: keyPoint.id || null,
          text: keyPoint.text
        })
      );
      this.$refs.editModal.show();
    },
    displayKeyPointsData() {
      const remarks = [...this.keyPoints];
      const editedDocument = {
        ...this.editingRowData,
        remarks,
        storeids: this.editingRowData.storeids
          ? this.editingRowData?.storeids?.split(',').map(item => item?.trim())
          : [],
        regions: this.editingRowData.regions ? this.editingRowData?.regions?.split(',').map(item => item?.trim()) : [],
        clusters: this.editingRowData.clusters
          ? this.editingRowData?.clusters?.split(',').map(item => item?.trim())
          : [],
        formats: this.editingRowData.formats ? this.editingRowData?.formats?.split(',').map(item => item?.trim()) : [],
        active: true
      };
      this.$refs.editModal.hide();
      if (editedDocument?.remarks[0]?.text?.trim() === '') {
        return;
      } else {
        const { id } = editedDocument;
        this.patchOne({
          type: 'document',
          id,
          document: editedDocument,
          router,
          redirectUrl: '/document-share'
        });
      }
    },
    cancelEditDocument() {
      this.$refs.editModal.hide();
    },
    hideModal() {
      this.showEditDocumentModal = false; // Close the modal
    },
    addKeyPoint() {
      this.keyPoints.push({ id: Date.now(), text: '' });
    },
    deleteKeyPoint(index) {
      this.keyPoints.splice(index, 1);
    },

    onAdd() {
      router.push('/document/new');
    },
    onEdit({ row }) {
      router.push(`/document/${row.id}`);
    },
    async onUpload() {
      await Vue.swal({
        title: 'Upload file',
        html:
          '<p id="text" class="swal2-text">Choose CSV file and Folder containing bulk upload. Please make sure that csv file contains column with reference to the file name.<p></br>' +
          '<label class="swal2-input-label">Choose CSV File</label>' +
          '<input id="csv-file" class="swal2-input" type="file">' +
          '<label class="swal2-input-label">Choose Bulk File Directory</label>' +
          '<input id="bulk-directory" class="swal2-input" type="file" webkitdirectory directory multiple>',
        preConfirm: () => {
          return [document.getElementById('csv-file').files, document.getElementById('bulk-directory').files];
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }
        if (!result.value[0].length) {
          Vue.swal({
            title: 'Error',
            text: 'Please choose csv file.',
            type: 'error',
            confirmButtonText: 'Ok',
            icon: 'error',
            background: this.isDarkMode ? '#464d54' : '#ffffff',
            customClass: {
              title: this.isDarkMode ? 'text-light' : 'text-dark',
              content: this.isDarkMode ? 'text-light' : 'text-dark'
            }
          });
          return null;
        }
        if (!result.value[1].length) {
          Vue.swal({
            title: 'Error',
            text: 'Please choose upload directory with files.',
            type: 'error',
            confirmButtonText: 'Ok',
            icon: 'error',
            background: this.isDarkMode ? '#464d54' : '#ffffff',
            customClass: {
              title: this.isDarkMode ? 'text-light' : 'text-dark',
              content: this.isDarkMode ? 'text-light' : 'text-dark'
            }
          });
          return null;
        }
        this.readCSV(result.value[0], result.value[1]);
        return null;
      });
    },
    readCSV(file, directoryData) {
      const reader = new FileReader();
      reader.onload = async () => {
        csv({
          noheader: true,
          output: 'csv'
        })
          .fromString(reader.result)
          .then(csvRow => {
            const data = [];
            const rows = csvRow.slice(1, csvRow.length);
            let noFileFoundError = false;
            for (let index = 0; index < rows.length; index++) {
              const element = rows[index];
              const fileFound = [...directoryData].filter(({ name }) => name === element[11]);
              if (!fileFound.length) {
                Vue.swal({
                  title: 'Error',
                  text: `File Not Found in directory ${element[11]}.`,
                  type: 'error',
                  confirmButtonText: 'Ok',
                  icon: 'error',
                  background: this.isDarkMode ? '#464d54' : '#ffffff',
                  customClass: {
                    title: this.isDarkMode ? 'text-light' : 'text-dark',
                    content: this.isDarkMode ? 'text-light' : 'text-dark'
                  }
                });
                noFileFoundError = true;
                break;
              }
              data.push({
                data: element,
                file: fileFound[0]
              });
            }
            if (!noFileFoundError) {
              this.uploadBulk(data);
            }
          });
      };
      reader.readAsBinaryString(file[0]);
    },
    async uploadBulk(data) {
      this.showUploadProgress = true;
      this.uploadProgress = 0;
      this.bulkUploadText = 'Uploading...';

      const uniqueRegions = {
        layout: new Set(),
        planogram: new Set(),
        sop: new Set()
      };

      const uniqueClusters = {
        layout: new Set(),
        planogram: new Set(),
        sop: new Set()
      };

      const uniqueFormats = {
        layout: new Set(),
        planogram: new Set(),
        sop: new Set()
      };

      const uniqueStoreids = {
        layout: new Set(),
        planogram: new Set(),
        sop: new Set()
      };

      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const fileName = `${element.file.name}`;
        const documentType = element.data[1].toLowerCase();

        const timeStamp = new Date().getTime();
        await this.uploadDocument({
          file: new File([element.file], fileName, { type: element.file.type }),
          timeStamp
        });

        const document = {
          title: element.data[0],
          documentType: documentType.toUpperCase(),
          description: element.data[2],
          active: element.data[3].toLowerCase(),
          startDate: moment(element.data[4]).format('YYYY-MM-DD'),
          endDate: moment(element.data[5]).format('YYYY-MM-DD'),
          regions: element.data[6].length > 0 ? element.data[6].split(',') : [],
          clusters: element.data[7].length > 0 ? element.data[7].split(',') : [],
          formats: element.data[8].length > 0 ? element.data[8].split(',') : [],
          storeids: element.data[9].length > 0 ? element.data[9].split(',') : [],
          remarks:
            element?.data[10]?.length > 0
              ? element.data[10]
                  .split(';')
                  .filter(i => i.trim() !== '')
                  .map((i, z) => ({ id: z + 1, text: i }))
              : [],
          documentName: `${timeStamp}-${fileName}`,
          uploadType: 'bulk_upload'
        };

        document.regions.forEach(region => uniqueRegions[documentType].add(region));
        document.clusters.forEach(cluster => uniqueClusters[documentType].add(cluster));
        document.formats.forEach(format => uniqueFormats[documentType].add(format));
        document.storeids.forEach(storeid => uniqueStoreids[documentType].add(storeid));

        await this.postOne({
          document,
          router,
          redirectUrl: ''
        });
      }

      const params = {
        layout: {
          regions: Array.from(uniqueRegions.layout),
          clusters: Array.from(uniqueClusters.layout),
          formats: Array.from(uniqueFormats.layout),
          storeids: Array.from(uniqueStoreids.layout)
        },
        planogram: {
          regions: Array.from(uniqueRegions.planogram),
          clusters: Array.from(uniqueClusters.planogram),
          formats: Array.from(uniqueFormats.planogram),
          storeids: Array.from(uniqueStoreids.planogram)
        },
        sop: {
          regions: Array.from(uniqueRegions.sop),
          clusters: Array.from(uniqueClusters.sop),
          formats: Array.from(uniqueFormats.sop),
          storeids: Array.from(uniqueStoreids.sop)
        }
      };
      this.sendNotifications({ params, router });

      this.showUploadProgress = false;
      this.uploadProgress = 0;
      this.bulkUploadText = 'Uploading...';

      this.listDocuments({ query: this.$route.query });
    },
    createCsv() {
      const csv = `Title,Document Type,Document Description,Active,Start Date(YYYY-MM-DD),End Date(YYYY-MM-DD),Region,Cluster,Formats,Store Id,Remarks(Seprated by ;),File Name\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'sampleFileForBulkUpload.csv';
      anchor.click();
    },
    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteOne({
            id: row.id
          });
        }
      });
    }
  }
};
</script>
<style>
.modal-dialog {
  max-height: 80vh;
  overflow-y: auto;
}
</style>
