<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title text-center">{{ title }}</h1>
      <document-form-box
        list-url="/document-upload"
        :id="id"
        document-type="document"
        :formType="formType"
        @add="onAdd"
        @edit="onEdit"
      ></document-form-box>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import DocumentFormBox from '../../components/DocumentFormBox.vue';

export default {
  name: 'DocumentForm',
  components: {
    DocumentFormBox
  },
  async mounted() {
    if (this.$route.name === 'document-new') {
      this.formType = 'new';
      this.id = null;
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;

      await this.getOne({
        type: 'document',
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      id: null
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Upload New Document' : 'Update Document';
    },
    ...mapState('document', ['loading'])
  },
  methods: {
    ...mapActions('document', ['getOne', 'postOne', 'patchOne']),
    onAdd({ document }) {
      this.postOne({
        document,
        router,
        redirectUrl: '/document-share'
      });
    },
    onEdit({ document }) {
      this.patchOne({
        type: 'document',
        id: this.id,
        document,
        router,
        redirectUrl: '/document-share'
      });
    }
  }
};
</script>
